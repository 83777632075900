import { BuildingFrame, TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { PORTFOLIO_PATH } from '@flock/shared-ui/src/routeConstants'
import AcqHouse1 from '../../images/houses/portfolio-house-1.webp'
import AcqHouse2 from '../../images/knox-street-house.webp'
import AcqHouse3 from '../../images/houses/portfolio-house-3.webp'
import SectionLayout from '../SharedComponents/SectionLayout'

const AgentsAcquisitionsSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SectionLayout name="agents-acquisitions" backgroundColor="trustBlue.main">
      <Grid item xs={12} display="flex" justifyContent="center">
        <Typography variant="h1">Recent Acquisitions</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          gap="16px"
          alignItems="center"
          justifyContent="space-between"
        >
          <BuildingFrame
            src={AcqHouse1}
            size={isMobile ? 'small' : 'medium'}
            variant="standard"
            state="CO"
            city="Denver"
            url={PORTFOLIO_PATH}
            hasBoxShadow
          />
          <BuildingFrame
            src={AcqHouse2}
            size={isMobile ? 'small' : 'medium'}
            variant="standard"
            state="TX"
            city="Houston"
            url={PORTFOLIO_PATH}
            hasBoxShadow
          />
          <BuildingFrame
            src={AcqHouse3}
            size={isMobile ? 'small' : 'medium'}
            variant="standard"
            state="CO"
            city="Golden"
            url={PORTFOLIO_PATH}
            hasBoxShadow
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Box width="256px">
            <TrackedButton variant="secondary" size="large" to={PORTFOLIO_PATH}>
              See our portfolio
            </TrackedButton>
          </Box>
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default AgentsAcquisitionsSection
